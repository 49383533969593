/** @jsx jsx */
import { jsx, Button, Styled } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import Layout, { Container } from "components/layout"
import { Small, Medium, Large, SubHead, ExtraLarge } from "components/type"
import Infographic, { InfographicImage } from "components/infographic"
import ImageOverlap, { ThreeImageOverlap } from "components/image-overlap"
import BoardMember from "components/board-member"

import Briefcase from "components/svgs/briefcase"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query PlaceholderImage {
      placeholder: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Container>
        <Styled.h1>Design System</Styled.h1>
        <Styled.h2>Typography</Styled.h2>
        <Small>Body - Small - Regular</Small>
        <Small sx={{ fontWeight: "bold" }}>Body - Small - Bold</Small>
        <Styled.p>Body - Regular - Bold</Styled.p>
        <Styled.p sx={{ fontWeight: "bold" }}>Body - Regular - Bold</Styled.p>
        <Medium>Body - Medium - Regular</Medium>
        <Medium sx={{ fontWeight: "bold" }}>Body - Medium - Bold</Medium>
        <Large>Body - Large - Regular</Large>
        <Large sx={{ fontWeight: "bold" }}>Body - Large - Bold</Large>
        <SubHead>Sub-head</SubHead>
        <SubHead sm>Sub-head - Extra</SubHead>
        <ExtraLarge>Big Number</ExtraLarge>
        <Styled.blockquote>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex e – <strong>Name</strong>
        </Styled.blockquote>

        <Styled.h2>Buttons</Styled.h2>
        <Styled.h3>Regular Buttons</Styled.h3>
        <Styled.p>
          <Button>Primary</Button>{" "}
          <Button variant="secondary">Secondary</Button>{" "}
          <Button variant="accent">Accent</Button>{" "}
          <Button variant="ghost">Ghost</Button>{" "}
        </Styled.p>
        <Styled.h3>Special Buttons</Styled.h3>
        <Styled.p>
          <Button variant="underline">Underline</Button>{" "}
        </Styled.p>
        <Styled.p>
          <Button variant="return">Return Home</Button>{" "}
        </Styled.p>
        <Styled.p>
          <Button variant="menu">Menu</Button>
        </Styled.p>
      </Container>

      <Infographic Icon={Briefcase} value="75%">
        of people found work or furthered their education after completing
        Connections
      </Infographic>

      <InfographicImage
        bg="accent"
        color="background"
        Icon={Briefcase}
        value="75%"
        image={data.placeholder.childImageSharp.fluid}
      >
        of people found work or furthered their education after completing
        Connections
      </InfographicImage>

      <InfographicImage
        bg="accent"
        color="background"
        Icon={Briefcase}
        value="75%"
        image={data.placeholder.childImageSharp.fluid}
        imageSide="left"
      >
        of people found work or furthered their education after completing
        Connections
      </InfographicImage>
      <ImageOverlap image={data.placeholder.childImageSharp.fluid}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam
      </ImageOverlap>
      <ImageOverlap
        image={data.placeholder.childImageSharp.fluid}
        imageSide="right"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam
      </ImageOverlap>
      <ThreeImageOverlap
        image={data.placeholder.childImageSharp.fluid}
        imageLeft={data.placeholder.childImageSharp.fluid}
        imageRight={data.placeholder.childImageSharp.fluid}
        imageSide="left"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam
      </ThreeImageOverlap>
      <ThreeImageOverlap
        image={data.placeholder.childImageSharp.fluid}
        imageLeft={data.placeholder.childImageSharp.fluid}
        imageRight={data.placeholder.childImageSharp.fluid}
        imageSide="right"
      >
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam
      </ThreeImageOverlap>
      <BoardMember
        image={data.placeholder.childImageSharp.fixed}
        name="John Doe"
        position="President"
      />
      <BoardMember
        image={data.placeholder.childImageSharp.fixed}
        name="John Doe"
      />
    </Layout>
  )
}

export default IndexPage
