/** @jsx jsx */
import { jsx } from "theme-ui"

const Icon = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="90"
    height="84"
    fill="none"
    viewBox="0 0 90 84"
    className={className}
  >
    <path
      fill="currentColor"
      d="M80.625 17.625H66.562a.938.938 0 01-.937-.938v-.937a15 15 0 00-15-15h-11.25a15 15 0 00-15 15v.938a.938.938 0 01-.938.937H9.376A9.375 9.375 0 000 27v16.875A5.625 5.625 0 005.625 49.5h30.75a.945.945 0 00.75-.375 9.323 9.323 0 0115.705 0 .944.944 0 00.75.375h30.75A5.626 5.626 0 0090 43.875V27a9.376 9.376 0 00-9.375-9.375zM39.375 8.25h11.25a7.5 7.5 0 017.425 6.428.938.938 0 01-.93 1.072H32.88a.938.938 0 01-.93-1.072 7.5 7.5 0 017.425-6.428zM45 69.188A11.381 11.381 0 0134.687 57c0-.296 0-.589.026-.881a.938.938 0 00-.937-.994H7.5a1.875 1.875 0 00-1.875 1.609c-.23 1.594-.507 3.214-.78 4.83-1.29 7.545-2.505 14.674.937 18.75a9.275 9.275 0 007.496 2.936h63.443a9.275 9.275 0 007.477-2.929c3.447-4.083 2.228-11.212.938-18.75-.274-1.616-.551-3.236-.78-4.83a1.875 1.875 0 00-1.856-1.616H56.223a.943.943 0 00-.937.994c0 .292.026.585.026.881A11.381 11.381 0 0145 69.188z"
    ></path>
    <path
      fill="currentColor"
      d="M45 63.563c2.589 0 4.688-2.939 4.688-6.563 0-3.624-2.1-6.563-4.688-6.563-2.589 0-4.688 2.939-4.688 6.563 0 3.624 2.1 6.563 4.688 6.563z"
    ></path>
  </svg>
)

export default Icon
